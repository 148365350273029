<template>
  <page-layout>
    <template slot="side">
      <h1 class="title is-2 has-text-weight-black">
        <span class="has-text-secondary" v-t="`retailer-benefits.label`"></span><br>
        <span v-t="`contact.lets-talk`"></span>
      </h1>
      <hr>
      <p class="subtitle">
        <i18n path="retailer-benefits.contact-us" tag='span'>
          <template #contact-link>
            <router-link :to="{ name: 'contact' }" v-t="`press.here`"></router-link>
          </template>
        </i18n>
      </p>
    </template>

    <template slot="mobile">
      <h1 class="title is-2 has-text-weight-black">
        <span class="has-text-secondary" v-t="`retailer-benefits.label`"></span>
        &nbsp;
        <span v-t="`contact.lets-talk`"></span>
      </h1>
      <p class="subtitle">
        <i18n path="retailer-benefits.contact-us" tag='span'>
          <template #contact-link>
            <router-link :to="{ name: 'contact' }" v-t="`press.here`"></router-link>
          </template>
        </i18n>
      </p>
      <hr>
    </template>

    <template slot="main">
      <div class="columns is-mobile is-multiline is-gapless has-text-centered">
        <div class="column is-2-tablet is-4-mobile" v-for="(retailer, key) in featuredRetailers" :key="key">
          <img v-lazy="retailer.image" :alt="retailer.domain" style="max-width: 68%">
        </div>
      </div>

      <div class="content">
        <p v-t="`retailer-benefits.content.pouch-and-retailers`"></p>
        <p v-t="`retailer-benefits.content.pouch-3-ways`"></p>

        <span class="has-text-weight-semibold" v-t="`retailer-benefits.content.shopping-tool-title`"></span>
        <hr>
        <p v-t="`retailer-benefits.content.shopping-tool`"></p>

        <span class="has-text-weight-semibold" v-t="`retailer-benefits.content.promotions-tool-title`"></span>
        <hr>
        <p v-t="`retailer-benefits.content.promotions-tool`"></p>

        <span class="has-text-weight-semibold" v-t="`retailer-benefits.content.email-social-title`"></span>
        <hr>
        <p v-t="`retailer-benefits.content.email-social`"></p>

        <p>
          <i18n path="retailer-benefits.drop-email" tag="span">
            <template #info-email>
              <a href="mailto:info@joinpouch.com">info@joinpouch.com</a>
            </template>
          </i18n>
        </p>
      </div>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState({
    featuredRetailers: state => state.featuredRetailers
  })
}
</script>
